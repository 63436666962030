import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";

import TextField from "../bootstrap/input";
import Modal from "../bootstrap/modal";
import { setPasswordMutation } from "../logic/user";

export default function ChangePassword(props) {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });

  const { onClose, user } = props;
  const formRef = useRef(null);

  const [setPassword] = useMutation(setPasswordMutation);

  async function handleSubmit({ password, newpassword, confirmpassword }) {
    await setState((prevState) => ({ ...prevState, processing: true }));
    try {
      if (confirmpassword !== newpassword) {
        return setState((prevState) => ({
          ...prevState,
          error: "Passwords don't match",
          processing: false,
        }));
      }
      if (password === newpassword) {
        return setState((prevState) => ({
          ...prevState,
          error: "New password cannot be same as old password",
          processing: false,
        }));
      }
  
      const result = await setPassword({
        variables: {
          id: user?.id,
          password: newpassword,
          oldPassword: password,
        },
      });
      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      console.log({err})
      const errorMessage = err.message.replace(/(GraphQL error:)/gi, "")  || "An error has occurred";
      await setState((prevState) => ({
        ...prevState,
        error: errorMessage,
        processing: false,
      }));
    }
  }

  return (
    <Modal
      title={"Change Password"}
      show
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto" className="ml-auto">
              <Button
                type="button"
                variant="black-text"
                disabled={state.processing}
                onClick={async () => {
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="yellow vw-button"
                type="submit"
                disabled={state.processing}
                onClick={() => formRef.current.submit()}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy ref={formRef} onValidSubmit={async (data) => handleSubmit(data)}>
        {state.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{state.error}</div>
            </Col>
          </Row>
        )}
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              inline
              label={"Old Password"}
              name="password"
              placeholder={"Old Password"}
              type="password"
              disabled={state.processing}
              required
            />
          </Col>
        </Row>
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              inline
              label={"New Password"}
              name="newpassword"
              placeholder={"New Password"}
              type="password"
              disabled={state.processing}
              required
            />
          </Col>
        </Row>
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              inline
              label={"Confirm Password"}
              name="confirmpassword"
              placeholder={"Confirm Password"}
              type="password"
              disabled={state.processing}
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
