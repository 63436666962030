import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import { getOperationName } from "apollo-utilities";

import TextField from "../bootstrap/input";
import Captcha from "../bootstrap/captcha2";
import Modal from "../bootstrap/modal";
import { updateUserMutation, getCurrentUserQuery } from "../logic/user";
import VerificationModal from "./verification";
import { createMobileVerificationMutation } from "../logic/mobile";

export default function ChangeDetails(props) {
  const { onClose, user } = props;
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });
  const [mobile, setMobile] = useAsyncSetState(user?.mobileNumber);

  const [data, setData] = useAsyncSetState(null);
  const [showVerification, setVerification] = useAsyncSetState(false);

  const [createMobileVerification] = useMutation(
    createMobileVerificationMutation
  );
  const formRef = useRef(null);
  const [updateUser] = useMutation(updateUserMutation);

  async function handleSubmit(input) {
    await setState((prevState) => ({ ...prevState, processing: true }));
    try {
      await updateUser({
        variables: {
          userId: user.id,
          input: {
            email: input?.email,
            mobileNumber: input?.mobile,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      return setState((prevState) => ({ ...prevState, processing: false }));
      // return onClose();
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error: err,
      }));
    }
  }

  return (
    <>
      {showVerification && (
        <VerificationModal
          data={data}
          onSubmit={async () => handleSubmit(data)}
          onClose={async () => setVerification(false)}
        />
      )}
      <Modal
        title={"Change Details"}
        show={!showVerification}
        // onRequestClose={() => onClose()}
        onClose={async () => onClose()}
        footer={
          <Container>
            <Row>
              <Col xs="auto ml-auto">
                <Button
                  className="btn-light"
                  type="button"
                  disabled={state.processing}
                  onClick={(e) => {
                    e.preventDefault();
                    return onClose();
                  }}
                >
                  {"Cancel"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="darkblue"
                  className="vw-button"
                  type="submit"
                  disabled={state.processing}
                  onClick={() => formRef.current.submit()}
                >
                  {"Update"}
                </Button>
              </Col>
            </Row>
          </Container>
        }
      >
        <Formsy
          ref={formRef}
          onValidSubmit={async (input) => {
            await setData(input);
            if (user?.mobileNumber !== input?.mobile) {
              try {
                const result = await createMobileVerification({
                  variables: {
                    captcha: input?.captcha,
                    captchaKey: input?.captchaKey,
                    mobileNumber: input?.mobile,
                  },
                  awaitRefetchQueries: true,
                });
                if (
                  result?.data?.classMethods?.MobileVerification
                    ?.createMobileVerification
                ) {
                  return setVerification(true);
                }
                return setState({
                  error: result?.errors[0]?.message || "Invalid Captcha",
                });
              } catch (err) {
                return setState({
                  ...state,
                  error: err?.message || "Invalid Captcha",
                });
              }
            }
            return handleSubmit(input);
          }}
        >
          {state.error && (
            <Row>
              <Col>
                <div className="alert alert-danger">{state.error}</div>
              </Col>
            </Row>
          )}
          <Row className="field-row mb-2">
            <Col>
              <TextField
                inline
                label={"First Name"}
                name="firstName"
                defaultValue={user.firstName}
                placeholder={"First Name"}
                disabled
                testid="firstName"
                required
              />
            </Col>
          </Row>
          <Row className="field-row mb-2">
            <Col>
              <TextField
                inline
                defaultValue={user.lastName}
                label={"Last Name"}
                name="lastName"
                placeholder={"Last Name"}
                testid="lastName"
                disabled
                required
              />
            </Col>
          </Row>
          <Row className="field-row mb-2">
            <Col>
              <TextField
                inline
                label={"Email"}
                name="email"
                placeholder={"Email"}
                defaultValue={user.email}
                testid="email"
                required
                validations="isEmail"
                validationErrors={{
                  isEmail: "Enter a valid email",
                }}
                disabled={state.processing}
              />
            </Col>
          </Row>
          <Row className="field-row mb-2 align-items-center inline no-gutters">
            <Col>
              <TextField
                inline
                name="mobile"
                type="number"
                defaultValue={user.mobileNumber}
                label="Mobile Number"
                placeholder="Mobile Number"
                required
                onChange={async (e) => setMobile(e?.target?.value)}
              />
            </Col>
          </Row>
          {mobile !== user?.mobileNumber && (
            <Row className="field-row mb-2 align-items-center inline no-gutters">
              <Col>
                <Captcha label="captcha" required inline />
              </Col>
            </Row>
          )}
        </Formsy>
      </Modal>
    </>
  );
}

